import { theme, useResponsive } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { Grid, Pagination } from "swiper/modules";

import { tracking } from "shared/data";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { discoveryWidgetPostMagazineQueueQuery$key } from "scmp-app/queries/__generated__/discoveryWidgetPostMagazineQueueQuery.graphql";

import Logo from "./logo.svg";
import {
  Container,
  Footer,
  Header,
  ItemsContainer,
  MoreLink,
  Publication,
  StyledContentItemPostMagazineDiscovery,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: discoveryWidgetPostMagazineQueueQuery$key;
};

export const PostMagazineDiscoveryWidget: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment discoveryWidgetPostMagazineQueueQuery on Query {
        postMagazineDiscoveryQueue: queue(filter: { name: "section_top_71" }) {
          items(first: 6) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...postMagazineDiscoveryItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const postMagItems = data?.postMagazineDiscoveryQueue?.items?.edges ?? [];
  const isMobile = useResponsive(theme.breakpoints.down("tablet"), false);
  const currentPageType = useCurrentPageType();

  const items = isMobile ? postMagItems : postMagItems.slice(0, 5);

  const renderedItems = useMemo(
    () =>
      items.map((item, index) => (
        <StyledContentItemPostMagazineDiscovery
          key={item.node.entityId}
          reference={item.node}
          withSummary={index === 0}
        />
      )),
    [items],
  );

  const renderSwiper = useCallback(
    () => (
      <SwiperContainer>
        <StyledSwiper
          grid={{
            fill: "row",
            rows: 2,
          }}
          modules={[Pagination, Grid]}
          pagination={true}
          spaceBetween={16}
        >
          {renderedItems.map((item, index) => (
            <StyledSwiperSlide key={index}>{item}</StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    ),
    [renderedItems],
  );

  if (items?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.PostMagazine,
        pgtype: currentPageType,
      }}
    >
      <Container className={className}>
        <Header>
          <Title>HIGHLIGHTS FROM</Title>
          <Logo />
        </Header>
        {isMobile ? renderSwiper() : <ItemsContainer>{renderedItems}</ItemsContainer>}
        <Footer>
          <MoreLink pathname="/postmag">MORE IN POSTMAG</MoreLink>
          <Publication>{`Content from SCMP's Specialist Publications`}</Publication>
        </Footer>
      </Container>
    </BaseLinkContextProvider>
  );
};

PostMagazineDiscoveryWidget.displayName = "PostMagazineDiscoveryWidget";
